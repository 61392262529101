const csvUserActivityHeader = [
    {
        key:"username",
        label:"Username",
    },
    {
        key:"lastLoginTime",
        label:"Last-Login-Time",
        color:"",
    },
    {
        key:"lastLogoutTime",
        label:"Last-Logout-Time",
        color:"",
    }
]

export default csvUserActivityHeader; 

