const quizHeader =[

    {
        id:"category",
        label:"Category",
        color:"",
    },
    
    {
        id:"quiz_name",
        label:"Quiz Name",
        color:"",
    },
    {
        id:"quiz_status",
        label:"Status",
        color:"",
    },

    {
        id:"quiz_description",
        label:"Description",
        color:"",
    },
    {
        id:"accessibility",
        label:"Accessibility",
        color:"",
    }
]

export default quizHeader; 