const csvSliderHeader = [
   
    {
        key:"imgUrl",
        label:"Image",
        color:"",
    },
    {
        key:"status",
        label:"Status",
        color:"",
    },
    {
        key:"link",
        label:"Link",
        color:"blue",
    },
]

export default csvSliderHeader; 