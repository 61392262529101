const csvUserheaders = [
    {
      key: "name",
      label: "User Name",
      color: "red",
    },
    {
      key: "email",
      label: "Email",
      color: "",
    },
    {
      key: "mobile",
      label: "Mobile",
      color: "",
    },
    {
      key: "role",
      label: "Role",
      color: "",
    },
   
  ];

export default csvUserheaders;