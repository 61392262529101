const categoryHeader = [
  {
    id: "cat_name",
    label: "Category Name",
    color: "",
  },
  {
    id: "cat_status",
    label: "Status",
    color: "green",
  },
  {
    id: "createdAt",
    label: "Created At",
    color: "blue",
  },
];

export default categoryHeader;
