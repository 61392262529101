const reviewHeader =[
    {
        id:"user_profile_image",
        label:"Profile Image",
        color:"",
    },
    
    {
        id:"user_name",
        label:"User Name",
        color:"",
    },
    {
        id:"review",
        label:"Review Message",
        color:"",
    },
    {
        id:"rating",
        label:"Rating",
        color:"",
    },
    {
        id:"status",
        label:"Status",
        color:"",
    },
]

export default reviewHeader; 