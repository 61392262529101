import { createTheme } from '@mui/material';

export const theme = createTheme({
  
    // h1: {
    //   fontFamily: ['Merriweather', 'sans-serif'].join(','),
    //   fontSize: 40,
    // },
    // h2: {
    //   fontFamily: ['Merriweather', 'sans-serif'].join(','),
    //   fontSize: 32,
    // },
    // h3: {
    //   fontFamily: ['Merriweather', 'sans-serif'].join(','),
    //   fontSize: 24,
    // },
    // h4: {
    //   fontFamily: ['Merriweather', 'sans-serif'].join(','),
    //   fontSize: 20,
    // },
    // h5: {
    //   fontFamily: ['Merriweather', 'sans-serif'].join(','),
    //   fontSize: 16,
    // },
    // h6: {
    //   fontFamily: ['Merriweather', 'sans-serif'].join(','),
    //   fontSize: 14,
    // },
    // p: {
    //   fontFamily: ['Lato', 'sans-serif'].join(','),
    //   fontSize: 14,
    // },
    // body: {
    //   fontFamily: ['Lato', 'sans-serif'].join(','),
    //   fontSize: 14,
    // },

});
