const userHeader = [
   
    {
        id:"name",
        label:"User Name",
        color:"",
    },
    {
        id:"joindate",
        label:"Join Date",
        color:"",
    },
    {
        id:"email",
        label:"Email",
        color:"",
    },
    {
        id:"mobile",
        label:"Mobile",
        color:"",
    },
    {
        id:"usertype",
        label:"User Type",
        color:"green",
    },
    {
        id:"role",
        label:"Role",
        color:"green",
    },
]

export default userHeader; 