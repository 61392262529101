const userActivityHeader = [
    {
        id:"username",
        label:"Username",
    },
    {
        id:"lastLoginTime",
        label:"Last-Login-Time",
        color:"",
    },
    ]

export default userActivityHeader; 

