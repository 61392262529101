const sliderHeader = [
   
    {
        id:"imgUrl",
        label:"Image",
        color:"",
    },
    {
        id:"status",
        label:"Status",
        color:"",
    },
    {
        id:"link",
        label:"Description",
        color:"green",
    },
    {
        id:"text",
        label:"Link",
        color:"blue",
    },
]

export default sliderHeader; 