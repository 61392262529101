const resultHeader =[
    {
        id:"quizName",
        label:"Quiz Name",
        color:"",
    },
    
    {
        id:"userName",
        label:"Participation Name",
        color:"",
    },
    {
        id:"totalScore",
        label:"Total Score",
        color:"",
    },
]

export default resultHeader; 