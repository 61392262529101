const recallHeader = [
  {
    id: "recall_name",
    label: "Recall Name",
  },
  {
    id: "status",
    label: "Status",
    color: "",
  },
  {
    id: "recall_title",
    label: "Recall Title",
  },

  {
    id: "link",
    label: "Link",
    color: "blue",
  },
];

export default recallHeader;
