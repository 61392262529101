const controlHeader = [
    {
      id: "title",
      label: "Title",
      color: "",
    },
    {
      id: "status",
      label: "Status",
      color: "green",
    },
    {
      id: "createdAt",
      label: "Created At",
      color: "blue",
    },
    
  ];
  
  export default controlHeader;
  