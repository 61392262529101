const questionHeader =[
    {
        id:"image",
        label:"Image",
        color:"",
    },
    
    {
        id:"quizname",
        label:"Quiz Name",
        color:"",
    },
    {
        id:"question_name",
        label:"Question Name",
        color:"",
    },
    {
        id:"qus_description",
        label:"Description",
        color:"",
    },
]

export default questionHeader; 