const csvCategoryheaders = [
    {
      key: "cat_name",
      label: "User Name",
      color: "green",
    },
    {
      key: "cat_status",
      label: "Category Status",
      color: "blue",
    },
   
    {
      key: "createdAt",
      label: "Created Time",
      color: "",
    },
  ];

export default csvCategoryheaders;