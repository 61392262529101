const studyHeader = [
    
    {
        id:"study_name",
        label:"Study Name",
    },
    {
        id:"status",
        label:"Status",
        color:"",
    },
    {
        id:"study_title",
        label:"Study Title",

    },
    
    {
        id:"link",
        label:"Link",
        color:"blue",
    },
]

export default studyHeader; 